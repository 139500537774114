import React from "react";
import { footer_navs, social_navs } from "../Utils/utils";
import { Link } from "react-router-dom";

import combined_certifications from "../Assets/Images/Misc/combined_certifications.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div
      className="fixed bottom-0 h-12 lg:h-20 w-full lg:flex items-center bg-second text-white
     px-16 justify-start  !z-[999999]"
    >
      <div className="hidden lg:w-[40%]  lg:block">
        <h2 className=" text-main font-semibold tracking-widest text-2xl leading-tight">
          Tiller
        </h2>
        <ul className="flex">
          {footer_navs.map((nav_item) => {
            return (
              <li className="capitalize font border-b-2 border-transparent hover:border-b-main pr-2 pl-1 ">
                <Link to={nav_item.url}>{nav_item.name}</Link>
              </li>
            );
          })}
        </ul>
      </div>

      <ul className="footer-nav-social-container">
        {social_navs.map((nav_item) => {
          return (
            <li className="footer-nav-social-item text-white ">
              <a className="text-white" href={nav_item.url}>
                {nav_item.icon}
              </a>
            </li>
          );
        })}
        <li className="col-span-full text-sm md:text-md">
          Legal and policies {year} Tiller. All rights Reserved.
        </li>
      </ul>
   
        <img
          className=" h-full"
          alt="combined_certifications"
          src={combined_certifications}
        />
      
    </div>
  );
};

export default Footer;

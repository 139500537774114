import axios from "axios";
import NEWS_CONTENT_LIST from "./NewsData";

const base_url = process.env.REACT_APP_BACKEND_URl;
const asset_url = process.env.REACT_APP_ASSET_URL



export const fetchNewsList = async (setter) => {
  const fallbackData = NEWS_CONTENT_LIST;
  try {
    const response = await axios.get(`${base_url}/news/active`);
   
    
    setter && setter(response.data?.activeNews?.length ? response.data?.activeNews?.map(item=>{return{...item,thumb_url:`${asset_url}/${item?.thumb_url}`}}) :fallbackData);
    
    
    return response.data?.activeNews?.length ? response.data?.activeNews?.map(item=>{return{...item,thumb_url:`${asset_url}/${item?.thumb_url}`}}) :fallbackData
  } catch (error) {
    // or any default value you'd like
    setter && setter(fallbackData);
    return fallbackData;
  }
};

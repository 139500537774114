import React, { useEffect } from 'react'
import Maintenance from './Maintenance'
import BreadCrumb from '../Components/BreadCrumb'
import { useNavigate } from 'react-router'
import Search from '../Components/Search'
import { product_list } from '../Utils/dummy_data'
import { SECTOR_WISE_PROJECTS_SHORT_DATA } from '../Utils/ProjectsData'
import '../Assets/Styles/projects.css'
import { Link } from 'react-router-dom'

const Projects = () => {
    const navigate = useNavigate()
    const handleProjectClick = (item) => {
        navigate(`/projects/${item.name}`);
    }
    const hanleProjectSelection = (e, item) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(`/projects/${item.project_id}`);
    }

    useEffect(() => {
        document.getElementById('head-title').text = 'Projects | Tiller'
    }, [])
    return (
        <div className=''>
            <BreadCrumb />
            {/* <div className='sticky top-[120px] left-0'>
                <Search type='project' />
            </div> */}


            <main className=' gap-container !pt-0 bg-second bg-opacity-10 text-second'>
                {SECTOR_WISE_PROJECTS_SHORT_DATA.map(item => {
                    return (<div className='py-6'>
                        <div className='py-4 border-b '>
                            <h2 className='text-xl font-semibold'>{item.name}</h2>
                        </div>
                        <div className='grid-container-8  '>
                            {item.projects?.length ? item.projects
                                .sort((list_item_prev, list_item_next) => { return list_item_prev.order - list_item_next.order })
                                .map(list_item => {
                                   
                                    return (<Link to={list_item?.status!=='dev'?`/projects/${list_item.project_id}`:''} className=' w-40  md:w-48 lg:56 h-full cursor-pointer transform hover:scale-105  ease-in-out
                                    transition-transform duration-1000'

                                       >
                                        <img src={list_item.thumb_image} className='shadow-lg' />
                                        <p className=' text-xs truncate capitalize font-semibold mt-2'>{list_item.short_name}</p>
                                        <p className='text-xs'>{list_item.location}</p>
                                    </Link>)
                                })
                                : <></>}
                        </div>
                    </div>)
                })}
            </main>

        </div>
    )
}

export default Projects
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { PROJECT_LIST, SECTOR_WISE_PROJECTS_SHORT_DATA } from '../Utils/ProjectsData'
import BreadCrumb from '../Components/BreadCrumb'
import ProjectDetailContent from '../Components/ProjectDetailContent'
import '../Assets/Styles/projects.css'


const ProjectDetails = () => {

    const [data_found, setData_found] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const project_id = useParams()?.id || null

    const project_details = PROJECT_LIST.find((item) => { return item?.id == project_id })


    const display_type_component = project_details?.display_type_component || null
    useEffect(() => {
        if (!(project_details || display_type_component)) {
            setData_found(false)
        }
        else {
            setData_found(true)
        }
    }, [])

    return (
        <div className='w-full '>

            <BreadCrumb />

            {data_found ? <>
                {!display_type_component ? <> <div className='cover'>
                    <img className='image' src={project_details?.cover_image} />
                    <h2 className='title'>{project_details?.title}</h2>
                </div>
                    <div className='facts-1' >
                        <div className='grid-container-2 gap-container !py-0 '>
                            <h2 className='title'>Project Facts</h2>
                            <div className='items'>
                                <div className='top-left'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill='#ffffff' viewBox="0 0 448 512"
                                        className='icon'>
                                        <path d="M368 80h32v32H368V80zM352 32c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3
                                 0 64v64c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32H320c0
                                  17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V384c0-17.7-14.3-32-32-32V160c17.7 0 32-14.3 
                                  32-32V64c0-17.7-14.3-32-32-32H352zM96 160c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32V352c-17.7 
                                  0-32 14.3-32 32H128c0-17.7-14.3-32-32-32V160zM48 400H80v32H48V400zm320 32V400h32v32H368zM48 112V80H80v32H48z" /></svg>
                                    <p className='title'>Location & Area</p>
                                    <p className='text'>{project_details?.location}</p>
                                    <p className='text'>( {project_details?.area} )</p>

                                </div>
                                <div className='top-right'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" fill='#ffffff' className='icon'>
                                        <path d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z" /></svg>
                                    <p className='title'>Funded By</p>
                                    <p className='text'>{project_details?.funded_by}</p>

                                </div>
                                <div className='bottom-left'>

                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" fill='#ffffff' className='icon'>
                                        <path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V128H16zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" />
                                    </svg>
                                    <p className='title'>Client</p>
                                    <p className='text'>{project_details?.client}</p>

                                </div>
                                <div className='bottom-right'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" stroke='#ffffff' fill='#ffffff' className='icon'>

                                        <path d="M97.99,50.15c-3.65-2.65-3.61-6.53,0.09-9.26c2.68-1.96,1.66-3.71,0.61-5.24l-13.71,7.91V34.7H59.25v-4.15  
                              c-1.36,1.31-2.28,3.21-3.21,5.07c-0.91,1.83-1.86,3.55,0.58,5.31c3.79,2.72,3.62,6.32,0.25,8.98c-3.88,3.06-0.88,5.35,0.39,7.6 
                                 c1.2,2.13,2.02,5.46,6.17,3.46c3.62-1.76,7.1,0.4,7.53,4.68c0.42,4.16,3.34,3.06,5.32,2.79c2.6-0.36,6.75,2.48,7.46-2.92 
                                    c0.63-4.81,3.5-6.14,7.71-4.47c4.42,1.75,5-2.15,6.38-4.31C99.02,54.9,101.49,52.7,97.99,50.15z M77.95,52.74 
                                       c-3.67-0.55-5.78-2.7-5.57-6.54c0.21-3.62,2.5-5.44,5.95-5.69c3.12-0.22,6.04,3.09,5.94,6.31C84.16,50.04,81,53.19,77.95,52.74z">
                                        </path>

                                        <path d="M42.6,2.93c-13.63,6.04-12.57,26.44,1.09,31.8c11.84,5.06,7.97-6.51,15.56-9.5v-8.1h8.59  
                                         C67.47,5.44,53.05-2.95,42.6,2.93z" >
                                        </path>
                                        <path d="M108.86,25.91L88.35,14.08v6.41c-3.81,0-22.34,0-25.73,0c0,3.03,0,7.84,0,10.86h25.73v6.41  
                                             C93.27,34.89,103.87,28.79,108.86,25.91z">
                                        </path>
                                        <path d="M67.11,67.86c-0.43-2.5-1.21-3.28-3.62-2.32c-5.8,2.3-7.71-2.4-9.69-5.96c-2.08-3.69-5.62-7.93-0.7-11.97 
                                                      c2.75-2.26,1.28-4.21-0.58-4.27c-5.63-0.21-10.75-3.24-16.39-2.77c-8.97,0.74-15.47,8.67-15.52,18.76   
                                                      c-0.04,8.81,2.36,11.22,11.23,11.25c4.86,0.02,9.72,0,14.59,0c5.98,0,11.96-0.01,17.94,0C66.2,70.59,67.57,70.56,67.11,67.86z" title="">
                                        </path>
                                        <path d="M28.13,38.63c-5.55,3.53-9.3,8.09-10.82,14.15c-0.72,3.81-3.96,2.48-6.78,2.73c-8.09,0-9.51-0.95-9.77-6.54  
                                                         c-0.38-7.91,3.02-12.63,9.54-13.31C18.82,34.78,23.52,35.47,28.13,38.63z">
                                        </path>
                                        <path d="M15.89,11.08c-5.85,0.07-10.26,4.61-10.05,10.35c0.19,5.23,5.06,9.89,10.28,9.84 
                              c5.46-0.06,10.12-5.02,9.96-10.61C25.93,15.12,21.55,11.02,15.89,11.08z">
                                        </path>

                                    </svg>

                                    <p className='title capitalize'>Rendered Service</p>
                                    <p className='text'>{project_details?.rendered_service}</p>

                                </div>

                            </div>
                        </div>

                    </div >

                    {project_details?.content?.sort((item_prev, item_next) => item_prev.order - item_next.order).map(item =>

                        <div className='gap-container bg-second text-white'>
                            <ProjectDetailContent {...item} />

                        </div>

                    )}
                    {project_details?.system_frame ? <div className=' bg-second text-[#f2f2f2]'>
                        <h2 className='py-2 text-3xl font-semibold text-center mb-4'> System Design and Demo</h2>
                        {project_details.system_frame}
                    </div> : <></>}

                </> : display_type_component}  {/* Handle Specific Layout Rendering for Project */}


                {/* Releted Sector-Projects  */}
                <div className='gap-container-1 grid-container-5 bg-second text-white '>
                    <h2 className='font-semibold py-2 text-xl col-span-full border-b-main border-b-2 w-fit pr-5'>{'Related Projects'}</h2>


                    {SECTOR_WISE_PROJECTS_SHORT_DATA?.filter(sector_item => {
                        return sector_item?.projects.map(item => item?.project_id).includes(project_details?.id)
                    })[0]?.projects?.map(item => {
                        return item?.project_id != project_details?.id ? <div className='relative group rounded-xl cursor-pointer '>
                            <a href={`/projects/${item?.project_id}`}>
                                <img src={item?.thumb_image} className='object-contain object-bottom h-64  w-full rounded-t-md  ' />
                                <div className='p-2 uppercase font-[700] tracking-wider rounded-b-md text-center'>
                                    <p className='text-sm truncate'>{item?.short_name}</p>
                                </div></a>

                        </div> : <></>
                    })}




                </div>
            </> : <div className='h-[79.6vh] relative flex justify-center items-center'>
                <p className='absolute left-1/2 -translate-x-1/2'>Loading</p>
                <div className=' h-20 w-20 border-yellow-300  mx-auto rounded-full border-t-4 border-r-8 animate-spin '>

                </div>
            </div>}

        </div >
    )
}

export default ProjectDetails
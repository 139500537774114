import React from 'react'

import { useNavigate } from 'react-router'
import TEAM_MEMBER_HOMEPAGE from '../Utils/TeamData'


const Team = () => {
  const navigate = useNavigate()
  return (
    <div className=''>
      <h2 className='text-5xl py-20 uppercase font-bold text-main'>{/* <a href='/people'> */}Our Team{/* </a> */}</h2>
      <div className=' grid  grid-cols-3  xl:grid-cols-6  md:gap-10'>
        {TEAM_MEMBER_HOMEPAGE.map(item => {
          return (<div className={`text-center mx-auto max-w-[165px] capitalize py-2 ${item?.description ? " col-span-full mx-auto" : ""} `}>
            <div className='h-20 w-20 md:w-44 md:h-44  rounded-full object-cover mx-auto mt-2 border overflow-hidden'>
              <img src={`/Assets/Emp_images/${item?.emp_id}.jpg`}
                alt={`${item?.name}`} key={`${item?.emp_id}_${item?.name}`} />
            </div>
            <p className='font-semibold  mx-auto cursor-pointer text-sm md:text-[16px] '
              /* onClick={() => handleEmployeeClick(item)} */>{item?.name}</p>
            <p className='font-thin text-xs md:text-sm'>{item.designation}</p>
            <p className='py-4 md:w-1/2 lg:w-1/3 px-8   text-lg text-center mx-auto'> {item?.description}</p>
          </div>)
        })}
      </div>
   {/*    <Link to={'/people'} className=' my-4 float-right group py-2 bg-main text-[#f2f2f2] px-6 rounded-full'>Meet Our Full Team
        <span className='hidden group-hover:inline ease-in-out transform transi'>{" >"}</span></Link> */}
    </div>
  )
}

export default Team
import React from "react";
import Transport_Rangpurr_Sylhet from "../../Assets/Images/Projects/Transport_Rangpurr_Sylhet.jpg";
import station from "../../Assets/Images/Projects/station.jpg";
import waterways from '../../Assets/Images/Projects/waterways.jpg';
import walkway from '../../Assets/Images/Projects/walkway.jpg';
import reconnissance_survey from "../../Assets/Images/Projects/reconnissance_survey.jpg";
import { photoObjectFormatter } from "./TVET";

const RSPFS2 = () => {
    const projectInfo = {
        location: 'Rangpur and Sylhet City Corporation',
        title: 'Transportation Surveys for Roadways and Waterways under Preparation of Risk Sensitive Database for Core Area of Rangpur and Sylhet District Town, Package 02',
        client: "Urban Development Directorate (UDD)",
        project_Value: '0.20 Cr / $182.87',
        rendered_service: 'Development/Strategic Planning',
        description_up: `The project, titled "Preparation of Risk-Sensitive Database for Core Area of Rangpur and Sylhet District Town," aims to establish a comprehensive set of roadway and waterway transportation surveys for the core areas of Rangpur and Sylhet districts. Roadway surveys encompass assessments of traffic volume, congestion levels, and the impact of developmental changes on roadways, including proposed infrastructure improvements. Additionally, the analysis focuses on evaluating roadway conditions, capacities, and the effectiveness of traffic signals, along with conducting origin-destination surveys to map travel patterns.`,
        description_down: `The project, titled "Preparation of Risk-Sensitive Database for Core Area of Rangpur and Sylhet District Town," aims to establish a comprehensive set of roadway and waterway transportation surveys for the core areas of Rangpur and Sylhet districts. Roadway surveys encompass assessments of traffic volume, congestion levels, and the impact of developmental changes on roadways, including proposed infrastructure improvements. Additionally, the analysis focuses on evaluating roadway conditions, capacities, and the effectiveness of traffic signals, along with conducting origin-destination surveys to map travel patterns.`,

        quote_1: `On the other hand, waterway transportation surveys concentrate on assessing traffic volume, cargo and passenger movement, and the condition of navigable water bodies and port facilities within the core area. These surveys aim to provide vital insights into traffic dynamics and infrastructure conditions crucial for urban transportation planning and development strategies in the specified districts.`,
        list: [
            { content: `Conduct in-depth transportation studies encompassing Trip Generation, Trip Distribution, and Travel Behavior Analysis through Household Surveys, meticulously gathering data on commuting patterns, travel preferences, and behaviors.` },
            {
                content: `Execute comprehensive Traffic Volume Studies to assess vehicular flow, congestion patterns, and traffic density in specific areas, aiding in infrastructure planning and traffic management strategies.`
            },
            {
                content: `Conduct meticulous Origin and Destination (O-D) Surveys to ascertain the origins and destinations of travel, evaluating travel patterns, traffic flows, and interconnectivity between different zones.`
            },
            {
                content: `Applying suitable transportation models to simulate and forecast travel demand, employing methodologies such as Gravity Models, Four-Step Models, or Activity-Based Models, aiding in infrastructure planning and policy formulation.`
            },
        ],
        waterways_image: photoObjectFormatter(waterways, 'Waterways'),
        sylhet_bus_terminal_image: photoObjectFormatter(station, 'Sylhet Bus Terminal'),
        walkway_image: photoObjectFormatter(walkway, 'Walkway'),
        osmani_international_airport_image: photoObjectFormatter(reconnissance_survey, 'Osmani International Airport , Sylhet'),




        professional_staff: [
            { name: `Dr. M. Shafiq-Ur Rahman`, designation: `Transport Planning Expert` },
            {
                name: `Tanvir Ahmed`,
                designation: `Transport Survey Expert)`,
            },

            {
                name: `Md. Mohaiminul Islam Rifat`,
                designation: `Transport Survey Supervisor-1`,
            },
            {
                name: `Fahmida Hanif`,
                designation: `Transport Survey Supervisor-2`,
            },

        ],
    };
    const { title, location, client, project_Value, rendered_service, list, professional_staff, description_down, description_up, quote_1, walkway_image, waterways_image, osmani_international_airport_image, sylhet_bus_terminal_image } = projectInfo;
    const gridItems = [
        { title: "Location and Area", content: location },
        { title: "Project Value", content: project_Value },
        { title: "Client", content: client },
        { title: "Rendered Service", content: rendered_service },

    ];
    const GridItem = ({ items }) => {
        return (
            <div className="flex  justify-around md:items-center gap-5 items-start">
                {items.map((item, index) => (
                    <div key={index} className="text-center ">
                        <b>{item.title}</b> <br />
                        <small>{item.content}</small>
                    </div>
                ))}
            </div>
        )
    }
    return (
        <div>
            <div className="relative ">
                <div className="h-[38.75rem]">
                    <img
                        src={Transport_Rangpurr_Sylhet}
                        alt="physical_feature_Rangpur_Sylhet "
                        className="w-full h-full  sm:object-cover "
                    />
                </div>
                <div className="px-[4.6762rem] py-[1.25rem] font-bold   bg-gray-800 text-white absolute bottom-0 h-[8.875rem] w-full   opacity-90 leading-[1.2]  lg:tracking-wide xl:tracking-tight 2xl:text-[2.8219rem]  xl:text-[2.2rem]  lg:text-[1.80rem]   md:text-[1.52rem]  ">
                    <p className=" text-justify">
                        {title}
                    </p>
                </div>
            </div>
            <div className="bg-[#e0e0d8] p-10 opacity-80 ">
                <GridItem items={gridItems} />
            </div>

            <div className='px-[8%] '>
                <div className='my-[1.625rem] w-[22.875rem]'>
                    <b className='font-bold text-[#333333] text-[1] md:text-[1.75rem]'>Project Activities</b>
                </div>
                <p className='leading-loose tracking-wide text-justify text-[.9rem]'>
                    {description_up}
                </p>

                <div className='my-[1.625rem] '>
                    <div className='h-[25rem]'>
                        <img src={waterways_image?.photo} alt='physical_feature_Rangpur_Sylhet ' className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{waterways_image?.caption}</i>
                </div>
                <div className='my-[1.625rem] '>
                    <div className='h-[25rem]'>
                        <img src={sylhet_bus_terminal_image?.photo} alt='station' className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{sylhet_bus_terminal_image?.caption}</i>
                </div>
                <div className='my-[1.625rem]'>
                    <p className='tracking-wider text-4xl leading-[2.625rem] text-justify text-[#84BE3F]'>
                        {quote_1}</p>
                </div>
                <div>
                    <ul className='list-disc leading-loose tracking-wide text-justify text-[.9rem]'>
                        {
                            list.map(({ content }, index) => (
                                <li key={index}>{content}</li>
                            ))
                        }


                    </ul>
                </div>


                <div className='my-[1.625rem] text-[.8rem] '>
                    <div className='h-[25rem]'>
                        <img src={osmani_international_airport_image?.photo} alt='Zilla_Parishad_Rangpur' className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{osmani_international_airport_image?.caption}</i>
                </div>
                <div className='my-[1.625rem] text-[.8rem] '>
                    <div className='h-[25rem]'>
                        <img src={walkway_image?.photo} alt='Zilla_Parishad_Rangpur' className='w-full h-full bg-cover sm:object-cover' />
                    </div>
                    <i>{walkway_image?.caption}</i>
                </div>

                <p className='leading-loose tracking-wide text-justify'>
                    {description_down}
                </p>



            </div>
        </div>
    );
};

export default RSPFS2;

import news_ccm from '../Assets/Images/News/ccm.jpg'
import news_job_placement from '../Assets/Images/News/ccm1.jpg'
import news_lged from '../Assets/Images/News/contract_lged.jpg'
import news_umcc from '../Assets/Images/News/UMCC.jpg'
import news_cuet from '../Assets/Images/News/news_cuet.jpg'
import news_risk_sens_database from '../Assets/Images/News/inaguration Risk-Sensitive Database for the Core Area of Rangpur and Sylhet District Town.jpg'
import _3up from '../Assets/Images/News/3up.jpg'
import srdp from '../Assets/Images/News/srdp.jpg'



const NEWS_CONTENT_LIST = [
  {
    headline: `UDD Director Praises Tiller's Innovative Surveying in “Preparation of Development Plan for 3 Upazilas (Pirojpur Sadar, Nesarabad, and Nazirpur Upazila) of Pirojpur District” Project`,
    thumb_url: _3up,
    body: `During a field visit, UDD Director M. Mahmud Ali praised Tiller’s advanced surveying techniques for the 'Preparation of Development Plan for 3 Upazilas of Pirojpur District' project. This  innovative approach will enhance the planning process.` /* `under the Ministry of Housing and Public Works in Sylhet.` */ /* `The inauguration occurred at the Sylhet DC office,
             in front of the mural of the Father of the Nation, Bangabandhu Sheikh Mujibur Rahman.` */,
    status: "active",
    published_at: "2024-09-17",
  },
  {
    headline: `UDD Officials Conduct Field Verification for SRDP project`,
    thumb_url: srdp,
    body: `UDD officials conducted a field check for attribute verification and reviewed BM values under the 'Risk-Sensitive Database for Rangpur & Sylhet' project. All BM values were correct, aiding efforts to address vulnerabilities in both districts.` /* `under the Ministry of Housing and Public Works in Sylhet.` */ /* `The inauguration occurred at the Sylhet DC office,
             in front of the mural of the Father of the Nation, Bangabandhu Sheikh Mujibur Rahman.` */,
    status: "active",
    published_at: "2024-09-17",
  },
  {
    headline: `The inauguration of the "Preparation of a Risk-Sensitive Database for the Core Area of Rangpur and Sylhet District Town"`,
    thumb_url: news_risk_sens_database,
    body: `The inauguration of the "Preparation of a Risk-Sensitive Database for the Core Area of Rangpur
             and Sylhet District Town" project took place last Thursday, implemented by the Urban Development Directorate
              (UDD) ` /* `under the Ministry of Housing and Public Works in Sylhet.` */ /* `The inauguration occurred at the Sylhet DC office,
             in front of the mural of the Father of the Nation, Bangabandhu Sheikh Mujibur Rahman.` */,
    status: "active",
    published_at: "2024-01-25",
  },
  {
    headline: "PLIS & CCM Workshop",
    thumb_url: news_ccm,
    body: `Development Project Proformance এর প্রণয়ন এবং মূল্যায়ণ আরো সহজ ও কার্যক্ষম করার লক্ষ্যে,Bangladesh Planning Commission এর অধীনে GIZ ফান্ডেড Adaptation to Climate Change into the National and Local Development Planning - II`,
    /* `বাংলাদেশ সরকারের প্ল্যানিং কমিশনের সকল সেক্টর ডিভিশন এবং বিভিন্ন মন্ত্রণালয় ও ২০টা এজেন্সীর প্রায় ৪০জন অংশগ্রহণকারীদের ,প্রজেক্টের ২টি মেজর আউটপুট Planning Information System (PLIS) এবং Climate Check Method (CCM) এর সাথে পরিচয় করিয়ে দিতে,বঙ্গবন্ধু আন্তর্জাতিক সম্মেলন কেন্দ্রে গত ১৬ই মার্চ আয়োজন করা হয় “Workshop on Planning Information System (PLIS) and Climate Check Method (CCM)” শীর্ষক একটি ইনসেপশন ওয়ার্কশপ।
            প্রকল্প পরিচালক জনাবা নুসরাত নোমান, যুগ্মপ্রধাণ, প্ল্যানিং কমিশন -এর সভাপতিত্বে ওয়ার্কশপে বিশেষ অতিথি ও প্রধাণ অতিথি হিসাবে উপস্থিত ছিলেন যথাক্রমে জনাব খন্দকার আহসান হোসেন, প্রধান (অতিরিক্ত সচিব) এবং জনাব সত্যজিত কর্মকার, সদস্য (সচিব), কার্যক্রম বিভাগ। অংশগ্রণকারীদের প্রতি প্রকল্প পরিচালক, বিশেষ অতিথি ও প্রধান অতিথির এর পাশাপাশি GIZ এর প্রিন্সিপাল অ্যাডভাইজর জনাব মাহমুদুর রহমান এর স্বাগত বক্তব্যের পর, Tiller -এর চীফ এক্সিকিউটিভ ও উক্ত প্রজেক্টের আইসিটি এক্সপার্ট জনাব তামজিদুল ইসলাম PLIS ও CCM এর উপর পরিচিতিমূলক বক্তব্য রাখেন।
            Tiller এর ট্রেইনিং এক্সপার্ট জনাবা তামান্না সালামের সঞ্চালনায়, ওয়ার্কশপের দ্বিতীয় অংশে প্রকল্পের ক্লাইমেট চেঞ্জ এক্সপার্ট জনাব তৌফিক মহিউদ্দিন অংশগ্রণকারীদের কাছে বিস্তারিতভাবে তুলে ধরেন CCM কী, এর উপযোগিতা ও CCM তৈরির পটভূমি। এ সেশনের পরের অংশে অংশগ্রহণকারীদের কাছ থেকে গ্রুপ ওয়ার্কের মাধ্যমে CCM সংক্রান্ত মতামত, উপদেশ ও সংশোধনী গ্রহণ করা হয়। ওয়ার্কশপের সর্বশেষ সেশনে PLIS সম্পর্কে বিস্তারিত আলোচনা করেন Tiller এর চীফ এক্সিকিউটিভ জনাব তামজিদুল ইসলাম এবং সিনিয়র সফটওয়্যার ইঞ্জিনিয়ার জনাব মোঃ আহসান ইকবাল; যেখানে প্রশ্নোত্তর ও আলোচনার মাধ্যমে তাঁরা অংশগ্রহণকারীদের দেখান কীভাবে PLIS ব্যবহার করে Climate Change এর আলোকে DPP প্রণয়ন ও মূল্যায়ণ খুব সহজেই করা যায়।` */ status:
      "active",
    published_at: "2023-06-21",
  },
  {
    headline: `Job Placement and business mentoring services for skills training graduates in five partner cities`,
    thumb_url: news_job_placement,
    body: `Today's kickoff meeting was held for “𝐉𝐨𝐛 placement and business mentoring support services for skills training
            graduates in five partner cities" project with Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) team.`,
    /* `Our honorable CEO sir Mr. Tamzidul Islam, Director of MAWTS Institute of Technology Mr. James Gomes along with the subject matter experts were present in the meeting. 
            #SkillsTraining #JobPlacement #TVET` */ status: "active",
    published_at: "2023-06-18",
  },
  /*  {
             headline: "Job Fair at MIST",
             thumb_url: basis,
             body: `We are glad to represent our organization at MIST Job Fair 2022. We believe in equity to recruit the right person in the right place.`,
 
             published_at: "2022-03-31",
             staus: 'hidden'
         }, */
  {
    headline: "Orientation for Leading LGED Officials",
    thumb_url: news_lged,
    body: `Orientation for Leading LGED Officials on “Application of Modern GIS technologies using Drones and Sub-surface/geological Data for 
           Urban and Infrastructure Development Planning”
           #GIS #drone #tiller #giz
           #LGED`,
    status: "active",
    published_at: "2023-01-06",
  },
  {
    headline:
      "Workshop on Photogrammetry technology for 3rd year student of CUET",
    thumb_url: news_cuet,
    body: `Day long workshop on Photogrammetry technology for 3rd year student of CUET.
             Organised by CUET planners alumni association.`,
    status: "active",
    published_at: "2023-11-12",
  },
  {
    headline:
      "Urban Management of Internal migration due to Climate change (UMCC)",
    thumb_url: news_umcc,
    body: `Successfully completed the Urban Management of Internal migration due to Climate change (UMCC)/ Urban Management of Migration and Livelihoods (UMML)
            Thank you BMZ, European Union and GIZ for your support`,
    status: "active",
    published_at: "2023-09-27",
  },
];
export default NEWS_CONTENT_LIST;



const TEAM_MEMBER_HOMEPAGE = [
    {

        name: 'Tamzidul Islam',

        emp_id: 1,

        department: 'Senior Management',

        designation: 'Founder & CEO',

        order: 1

    },{

        name: 'Brig Gen Md. Shameem Ahsan Jaglul, SPP, psc (Retd.)',

        emp_id: 103,

        department: 'Senior Management',

        designation: 'Chairman',

        order: 1

    }, {
        name: 'Prof. Dr. Ahsanul Kabir',

        emp_id: 101,

        department: 'Consultants',

        designation: <p>Sr. Consultant,<br />Transportation, Urban Planning</p>,

        order: 1

    },

    {

        name: 'Md. Anisur Rahman',

        emp_id: 102,

        department: 'Consultants',

        designation: <p>Sr. Consultant,<br />Urban Planning,Climate Change and Disaster Management</p>,

        order: 2

    }, {

        name: 'Md. Zillur Rahman',

        emp_id: 999,

        department: 'Consultants',

        designation: <p>Sr. Consultant,<br /> (GIS & RS)</p>,

        order: 4

    },

    {

        name: 'SK Mazharul Islam',

        emp_id: 63,

        department: 'Consultants',

        designation: <p>Sr. Consultant, <br /> Software Engineering & Architecture, Enterprise Application Integration,</p>,

        order: 3

    },



]
export default TEAM_MEMBER_HOMEPAGE